import * as React from 'react';
import {Helmet} from 'react-helmet';
import {documentToReactComponents} from '@contentful/rich-text-react-renderer';

import Layout from '@components/layouts/layout';
import Navigation from '@components/nav-footer/navigation';
import Footer from '@components/nav-footer/footer';

import {Container, Row, Col} from 'react-bootstrap';

const PrivacyPolicy = ({pageContext}) => {
  return (
    <Layout>
      <Helmet>
        <title>Polityka prywatności – Atakama Studio</title>
        <meta property="og:title" content={`Polityka prywatności – Atakama Studio`} />
      </Helmet>

      <Navigation />

      <div className="privacy-policy">
        <Container className="privacy-policy__container">
          <Row>
            <Col className="mb-5">
              <h1>Polityka prywatności</h1>
            </Col>
          </Row>
          <Row>
            <Col md={8}>{documentToReactComponents(pageContext.privacyPolicy)}</Col>
          </Row>
        </Container>
      </div>

      <Footer />
    </Layout>
  );
};

export default PrivacyPolicy;
